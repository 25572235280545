@import "_mixins.scss";

$padding: 24px;
$borderColor: $grey200;
$textColor: $grey900;
$textColorSecondary: $grey500;
$bgColor: $grey100;
$headerHeight: 60px;
$sidebarWidth: 250px;
$sm: 600px;
$md: 960px;
/**************
lg, large: 1280px
xl, extra-large: 1920px
*************/

.valign-center {
    top: 50%;
    @include translate(0, -50%);
}

.one-line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.shadow {
    &-base {
        border-radius: 0;
    }

    box-shadow: 0px 0px 8px rgba(12, 37, 86, 0.22);
    @extend .shadow-base;

    &-sm {
        box-shadow: 0px 0px 4px rgba(12, 37, 86, 0.12);
        @extend .shadow-base;
    }
}

.display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex {
    &-grow-1 {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    &-column {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.full-height {
    @extend .flex-grow-1;

    &-container {
        @extend .flex-grow-1;
        @extend .display-flex;
        @extend .flex-column;
    }
}

.MuiCard-root {
    border-radius: 0;
    @extend .shadow-sm;
    .MuiCardContent-root {
        padding: $padding;

        &.base {
            padding: 0;
        }
    }
    .MuiCardActions-root {
        padding: $padding;
    }
    .MuiCardHeader-root {
        padding: $padding;
        color: $textColor;
        .MuiCardHeader-title {
            font-size: 25px;
            font-weight: 700;
        }
    }
}
.card {
    &-main {
        min-height: 100%;
        .card-top {
            padding-top: 19px;
            padding-bottom: 17px;
            background-color: $bgColor;
            padding-left: $padding;
            position: relative;
            .MuiCardHeader-root {
                padding: 0 $padding 0 0;

                .card-category {
                    font-size: 12px;
                    color: $textColorSecondary;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .MuiCardHeader-action {
                    align-self: center;
                }
            }
        }
    }

    &-sub {
        .MuiCardHeader-root {
            padding-top: 13px;
            padding-bottom: 13px;
        }
    }

    &-modal {
        position: absolute;
        margin: 15px 0;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        width: calc(100% - 30px);
        max-width: 500px;
    }
}
.button-base {
    border-radius: 26px;
}
.MuiButtonGroup-root {
    @extend .button-base;
}
.MuiButton-root {
    @extend .button-base;

    font-weight: 600;
    padding-top: 7px;
    padding-bottom: 7px;
    text-transform: none;

    &.MuiButton-sizeMedium {
        min-width: 85px;
        font-size: 15px;
    }

    &.MuiButton-outlined {
        border-width: 2px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.bg-gradient {
    background: linear-gradient(180deg, $secondaryMain 0%, $primaryMain 100%);

    &-light {
        background: linear-gradient(180deg, rgba($secondaryMain, 0.1) 0%, rgba($primaryMain, 0.1) 100%);
    }
}
.btn-primary {
    &.MuiButton-contained,
    &.MuiIconButton-root {
        @extend .bg-gradient;
    }
    &.MuiIconButton-root {
        color: #ffffff;
    }
}
.divider-btn {
    height: 30px;
}

.btn {
    &-icon-sm {
        padding: 0;
        font-size: 18px;
        width: 30px;
        height: 30px;

        .icon {
            @extend .icon-default;
        }

        &:hover {
            @extend .bg-gradient;
            color: white;
        }
    }
    &-action {
        color: $textColorSecondary;

        .MuiAvatar-root {
            color: inherit;
            background-color: $secondaryLight;
        }
    }

    &-link {
        &.MuiButton-root {
            padding: 0 10px;
            min-width: 0;
        }
    }
}

.table-container {
    padding-left: $padding;
    padding-right: $padding;
}

.MuiTableCell-root {
    border-color: $borderColor;
    padding: 8px 15px;
    &.MuiTableCell-head,
    &.MuiTableCell-head h6 {
        font-size: 0.75rem;
        color: $textColorSecondary;
        font-weight: 500;
        text-transform: uppercase;
    }

    &.MuiTableCell-footer {
        font-size: 15px;
    }

    .btn-action {
        @extend .btn-icon-sm;
    }

    &.cell-dual {
        width: 50%;
        vertical-align: top;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

.table-excel {
    .MuiTableCell-root {
        border-right: 1px solid rgba(224, 224, 224, 1);
    }
}

.MuiDivider-root {
    border-color: $borderColor;
}
.MuiInputBase-root {
    .MuiInputBase-input {
        color: $textColor;
        font-size: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        &::placeholder {
            color: $textColorSecondary;
        }
        &[readonly] {
            background-color: $grey100;
        }
    }
}
.MuiOutlinedInput-root {
    background: transparent;
    border-radius: 3px;

    .MuiOutlinedInput-notchedOutline {
        border-color: $borderColor;
    }

    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }
    }

    .MuiInputAdornment-root {
        color: $textColorSecondary;

        .icon {
            @extend .icon-default-stroke;
            width: 1.1rem;
            height: 1.1rem;
        }
    }
}
.cell-error {
    .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
            border-color: red;
        }
    }

    &:before {
        content: attr(title);
        font-size: 10px;
        line-height: 1;
        background-color: red;
        color: #ffffff;
        padding: 3px;
        position: absolute;
        z-index: 1;
        margin-top: -8px;
        margin-left: -3px;
    }
}

.select-dropdown {
    .MuiInputBase-root {
        &::before {
            border-bottom-width: 0 !important;
        }
        .MuiInputBase-input {
            color: $secondaryMain;
            font-size: 13px;
            font-weight: 600;
            background: transparent;
        }
    }
    .MuiSelect-icon {
        font-size: 17px;
        color: $secondaryMain;
        right: 0;
        @extend .valign-center;
    }
    .MuiSelect-select {
        padding-right: 19px !important;
        &:focus {
            background-color: transparent;
        }
    }
    .MuiInput-root:after {
        border-width: 0;
    }
}
.icon-default-stroke {
    stroke-width: 1.5;
}
.icon-default {
    @extend .icon-default-stroke;
    width: 1.3rem;
    height: 1.3rem;
}
.list-default-width {
    min-width: 234px;

    &-sm {
        min-width: 160px;
    }
}
.list-default {
    @extend .list-default-width;
    .MuiListItem-root {
        padding-top: 5px;
        padding-bottom: 5px;
        .MuiListItemIcon-root {
            min-width: 40px;
            color: $textColorSecondary;

            .icon {
                @extend .icon-default;
            }
        }
        .MuiListItemText-root {
            color: $textColor;
            .MuiTypography-root {
                font-size: 15px;
            }
        }

        &:hover {
            @extend .bg-gradient-light;
            .MuiListItemIcon-root {
                color: $secondaryMain;
            }
        }
    }
}
.dropdown {
    @extend .shadow;

    .MuiCardContent-root {
        padding: 0;
    }
}
.MuiList-root {
    @extend .list-default;
    @extend .list-default-width-sm;
}
.MuiMenu-paper {
    @extend .dropdown;
    .MuiList-root {
        @extend .list-default;
        @extend .list-default-width-sm;
    }
}
.action {
    &-container {
        display: inline-block;
        vertical-align: top;
    }
    &-default {
        .MuiMenu-paper .MuiList-root,
        .MuiList-root {
            @extend .list-default-width;
        }
    }
    &-default-sm {
        .MuiList-root {
            @extend .list-default-width-sm;
        }
    }
}
.link {
    cursor: pointer;
}
.MuiAvatar-root {
    width: 30px;
    height: 30px;
}
.sides {
    padding: 0 16px;
    @media (min-width: $md) {
        padding-right: $padding;
        padding-left: $padding;
    }
}
.header {
    &-height {
        height: $headerHeight;
    }
    &-left {
        @extend .header-height;
        @extend .sides;

        @media (min-width: $md) {
            width: $sidebarWidth;
            padding-right: 10px;
        }
    }
    &-right {
        @extend .sides;
        @extend .shadow-sm;
        @extend .header-height;

        background-color: #fff;
        color: $textColorSecondary;

        .header-notification {
            padding: 0 20px;
        }

        .header-search {
            width: 100%;
            @extend .header-height;
            border-right: 1px solid $borderColor;
        }
        .header-select-organization {
            @extend .header-height;
            border-right: 1px solid $borderColor;
            padding-left: 15px;
            padding-right: 15px;
        }
        .header-upload {
            @extend .header-height;
            border-right: 1px solid $borderColor;
            padding: 10px 15px;
            .file-name {
                color: $textColor;
            }

            &.header-upload-full {
                position: absolute;
                z-index: 1;
                background-color: #ffffff;
                top: 0;
                right: 0;
                left: 62px;
                text-align: right;
                height: auto;

                @media (min-width: $md) {
                    left: $sidebarWidth;
                }
            }

            &.header-upload-empty {
                .file-upload-mini .file-drop-text {
                    display: none;
                    @media (min-width: $md) {
                        display: block;
                    }
                }
            }

            &.show-url {
                .file-upload {
                    display: none;
                }

                .copy-url {
                    @extend .one-line;
                    color: $textColor;

                    .url {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    &-search-popup {
        z-index: 1100;
        width: 100%;
        top: -60px !important;

        .MuiCard-root .MuiCardContent-root {
            padding-bottom: 11px;
            padding-top: 26px;
        }
        @media (min-width: $md) {
            display: none !important;
        }
    }
}
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1301;
    > * + * {
        margin-top: 16px;
    }
}

.list-notification {
    width: 100%;
    max-width: 300px;
    @media (min-width: $sm) {
        max-width: 330px;
    }

    .list-item {
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
            background-color: $secondaryLight;
        }
    }
}

.header {
    background-color: $bgColor;

    &.with-nav {
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .MuiToolbar-root {
        padding-left: 0;
        padding-right: 0;
    }
}
.main-content {
    width: 100%;
    min-height: calc(100vh - #{$headerHeight});
    margin-top: $headerHeight;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    @media (min-width: $md) {
        width: calc(100% - #{$sidebarWidth});
        margin-left: -$sidebarWidth;
    }

    &.with-nav {
        margin-left: 0;
    }
}

.sidebar {
    @media (min-width: $md) {
        width: $sidebarWidth;
        flex-shrink: 0;
    }

    &-paper {
        width: $sidebarWidth;
        background: $bgColor;
        color: $textColor;
        border-right-color: $borderColor;
        @media (min-width: $md) {
            top: 60px;
        }
    }

    &-logo {
        padding: 14px $padding;
    }

    &-content {
        height: 100%;
        @media (min-width: $md) {
            height: calc(100% - #{$headerHeight});
        }
    }
}
.gradient {
    &-color1 {
        stop-color: $secondaryMain;
    }
    &-color2 {
        stop-color: $primaryMain;
    }
}
.menu {
    &-container {
        height: 100%;

        .MuiList-root:first-child {
            padding-top: 0;
        }
    }
    &-group-title {
        font-size: 0.75rem;
        color: $textColorSecondary;
        padding: 0 $padding;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    &-divider {
        margin-top: 2px;
        margin-bottom: 10px;
    }

    &-item-hover {
        @extend .bg-gradient-light;

        .menu-item-text {
            color: #0f2351;

            h5 {
                color: inherit;
            }
        }

        .menu-item-icon {
            .icon-custom {
                * {
                    fill: url(#gradient_default);
                }
            }
        }

        .menu-item-icon-list {
            svg {
                color: $secondaryMain;
            }
        }
    }

    .menu-item {
        margin-bottom: 5px;
        align-items: center;

        .menu-item-icon {
            margin-top: auto;
            margin-bottom: auto;
            color: $textColorSecondary;

            .icon-custom {
                * {
                    fill: $textColorSecondary;
                }
            }
        }
        .menu-item-text {
            font-weight: 600;
            color: $grey700;
        }

        &:hover {
            @extend .menu-item-hover;
        }

        &.Mui-selected {
            @extend .menu-item-hover;

            &:hover {
                @extend .menu-item-hover;
            }
        }

        &.job-passed {
            .menu-item-icon {
                color: #00c600;
            }

            &:hover,
            &.Mui-selected,
            &.Mui-selected:hover {
                .menu-item-icon-list {
                    svg {
                        color: inherit;
                    }
                }
            }
        }
        &.job-failed {
            .menu-item-icon {
                color: #ff623c;
            }

            &:hover {
                .menu-item-icon-list {
                    svg {
                        color: inherit;
                    }
                }
            }
        }
    }
}

.form {
    .MuiInputLabel-root {
        font-size: 13px;
        font-weight: 600;
        color: $textColor;
        padding-bottom: 2px;
    }
}

.summary-item {
    .summary-label {
        color: $textColorSecondary;
    }

    .summary-value {
        font-size: 25px;
        color: $textColor;

        &.debit {
            color: $orangeMain;
        }
    }
}

.color {
    &-secondary {
        color: $textColorSecondary;
    }
}

.file-upload {
    background-color: $bgColor;
    border: 1px dashed $textColorSecondary;
    @include border-radius(10px);
    position: relative;

    &.file-upload-normal {
        min-height: 150px;
        margin-bottom: 24px;
        padding: 15px;

        .file-drop {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .file-drop-text {
                position: absolute;
                left: 0;
                right: 0;
                @extend .valign-center;
            }
        }
    }

    &.file-upload-mini {
        max-width: 100%;
        padding: 5px;

        @media (min-width: $md) {
            min-width: 200px;
        }

        .file-upload-top {
            @extend .display-flex;
            -ms-flex-align: center;
            align-items: center;
        }
        .file-upload-action {
            padding: 0 5px;
        }

        .file-drop {
            @extend .flex-grow-1;
        }
        .files {
            @extend .flex-grow-1;
            padding: 0 5px;
        }

        .file-drop-text {
            padding-right: 5px;
        }
    }

    .file-upload-action {
        position: relative;
        z-index: 1;
    }

    .file-drop {
        &.active {
            border: 1px dashed $secondaryMain;
            @include border-radius(10px);
        }

        .file-drop-text {
            font-size: 13px;
            color: $textColorSecondary;
            text-align: center;
        }
    }
}

.nowrap {
    white-space: nowrap;
}

.grid {
    .buttons {
        padding: 8px 0;
        min-height: 40px;
    }

    tr.is-selected {
        border-left: 1px solid $primaryMain;
    }
}

.invisible {
    visibility: hidden;
}

.pdf {
    &-container {
        position: relative;

        &.MuiCard-root .MuiCardContent-root {
            padding: 0;
        }
    }

    &-viewer {
        width: 100%;
        height: 100%;
        position: absolute;

        &.pdf-viewer-top {
            z-index: 999;
        }
    }
}

.sort {
    cursor: pointer;
    svg {
        display: none;
        font-size: 15px;
        vertical-align: top;
        margin-top: 2px;
        margin-left: 5px;
        color: $primaryMain;
    }

    &.asc {
        .down {
            display: inline-block;
        }
    }

    &.desc {
        .up {
            display: inline-block;
        }
    }
}

.report {
    #designer-host,
    #viewer-host {
        width: 100%;
        height: 100vh;
    }
}

.hidden {
    display: none;
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding: 0 0.5rem;
    color: #6c757d;
    content: "/";
}

.MuiTooltip-tooltip {
    .breadcrumb {
        display: block;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: none;
    }
}

.row {
    &-index {
        background-color: #f4f7fc;
        text-align: center;
    }

    &-unmatch {
        background-color: rgba(255, 0, 0, 0.2);
        td {
            height: 47px;
        }
    }

    &-ignore {
        background-color: rgba(0, 128, 0, 0.2);
    }

    &-propose {
        background-color: rgba(255, 255, 0, 0.2);
    }

    &-info {
        background-color: rgba(200, 200, 200, 0.2);
    }

    &-selectable {
        border-left: 3px solid transparent;
    }

    &-selected {
        border-left: 3px solid $primaryMain;
    }

    .external-id {
        @extend .one-line;
        max-width: 70px;
    }

    .comment {
        @extend .one-line;
        max-width: 200px;
    }

    &-single {
        .txt-comment {
            width: 200px;
        }
    }

    &-dual {
        .comment {
            max-width: 100px;
        }
    }
}
.ui-sortable {
    .sort-item {
        cursor: grab;
    }
    &-helper {
        background-color: rgba(200, 200, 200, 0.2);

        td {
            border-color: transparent;
        }
        .action-container {
            visibility: hidden;
        }
    }
}

.z-1 {
    position: relative;
    z-index: 1;
}

.dot {
    margin-left: 5px;
    &-red {
        color: red;
    }
    &-green {
        color: green;
    }
}

.toast {
    &-info {
        .icon {
            color: $primaryMain;
        }
    }
}

.print {
    &-verification-org {
        font-weight: bold;
    }
    &-title {
        font-size: large;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.dialog-custom {
    .MuiDialog-paper {        
        @media (min-width: $sm) {
            min-width: 500px;
        }
    }
}
