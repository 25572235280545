@mixin translate($x, $y) {
    transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -webkit-transform: translate($x, $y);
}

@mixin border-radius($x...) {
    -moz-border-radius: $x;
    -webkit-border-radius: $x;
    border-radius: $x;
}